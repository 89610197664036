<template>
  <div class="dialog">
    <div class="dialog-header">
      <div class="row">
        <div class="col">
          <p class="dialog-title text-uppercase mb-0">Edit Classroom</p>
        </div>
        <div class="col">
          <img class="close-button"
               src="../../assets/images/close.png"
               alt="close-button"
               @click="$emit('close')" />
        </div>
      </div>
    </div>

    <div class="dialog-content"
         style="overflow-y: auto;"
         v-bind:style="{height: contentHeightOverride}">
      <form @submit.prevent="editClassroom">
        <b-input-group prepend="Classroom Name">
          <b-form-input id="classroom-name"
                        type="text"
                        aria-describedby="formClasroomName"
                        placeholder="Classroom Name"
                        :state="!$v.form.classroomName.$error"
                        v-model="form.classroomName"
                        @input="$v.form.classroomName.$touch()">
          </b-form-input>

          <b-form-invalid-feedback id="formClasroomName">
            <span v-if="!$v.form.classroomName.required">
              Classroom name is required
            </span>
            <span v-if="$v.form.classroomName.required &&
              !$v.form.classroomName.uniqueClassroom">
              Classroom name must be unique.
            </span>
            <span v-if="!$v.form.classroomName.safeClassroomName">
              Classroom name not allowed.
            </span>
            <span v-if="!$v.form.classroomName.maxLength">
              Classroom Name should have a maximum of
              {{ $v.form.classroomName.$params.maxLength.max }}
              characters
            </span>
          </b-form-invalid-feedback>
        </b-input-group>

        <b-input-group prepend="Show QR Code">
          <toggle-button class="large-font ml-2 mb-0"
                         id="showQRCode"
                         v-model="form.showQRCode"
                         :width="100"
                         :height="50"
                         :labels="{
                           checked   : 'On',
                           unchecked : 'Off',
                         }" />
        </b-input-group>

        <div class="create-classroom-row">
          <div class="row">
            <div class="col-12 col-sm-4 d-flex align-items-center my-auto
              text-center">
              <!-- Selected Avatar -->
              <div class="photo-placeholder"
                   v-if="selectedThumbnail != null" >
                <i class="fas fa-times-circle remove-icon"
                   @click="resetSelected">
                </i>
                <img class="current-img img-fluid"
                     :src="selectedThumbnail"
                     alt="selected-avatar"
                     width="166"
                     height="166" />
              </div>
              <!-- Photo Cropping -->
              <div class="photo-placeholder" v-else>
                <croppa v-model="file"
                        accept=".jpeg,.png,.jpg"
                        :width="166"
                        :height="166"
                        :placeholder="placeholder"
                        placeholder-color="#068bcd"
                        :placeholder-font-size="12"
                        :prevent-white-space="true"
                        canvas-color="#fff"
                        :show-remove-button="true"
                        remove-button-color="red"
                        :remove-button-size="25"
                        :show-loading="true"
                        :loading-size="25"
                        :initial-image="currentImage"
                        @file-type-mismatch="onFileTypeMismatch"
                        @new-image="imageUpdate('new')"
                        @image-remove="imageUpdate('removed')">
                </croppa>
                <div class="buttons pb-2">
                  <i class="fas fa-search-plus icon"
                     @click="file.zoomIn()">
                  </i>
                  <i class="fas fa-search-minus icon"
                     @click="file.zoomOut()">
                  </i>
                </div>
              </div>
            </div>
            <div class="col-12 col-sm-8 text-center">
              <div class="avatars">
                <p class="small text-white mt-3">
                  Pick a thumbnail <br> (H3 Characters)
                </p>
                <div class="avatars-row">
                  <span class="position-relative ml-1 mr-1"
                        v-for="(avatar, index) in avatars"
                        :key="index"
                        @click="selectThumbnail(index)">
                    <img class="avatar border"
                         :class="{
                           'border-dark': (index == form.selected) ?
                             true : false
                         }"
                         :src="avatar.img"
                         :alt="avatar.name" />
                  </span>
                  <!-- <div class="row">
                    <div class="col-6 col-sm-5 col-md-4 text-center"
                         v-for="(avatar, index) in avatars"
                         :key="index">
                      <div class="avatar-wrapper border"
                           :class="{
                             'border-dark':(index==form.selected) ? true : false
                           }"
                           @click="selectThumbnail(index)">
                        <img class="avatar"
                             :src="avatar.img"
                             :alt="avatar.name" />
                      </div>
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="text-right">
          <b-button class="btn btn-success btn-h3-small"
                    type="button"
                    :disabled="isLoading || $v.form.$invalid || !editedForm"
                    @click="editClassroom">
            Done
          </b-button>
        </div>
      </form>

      <!-- Loading -->
      <loading :active.sync="isLoading"
               :is-full-page="true"
               background-color="#000"
               color="#068bcd"
               :opacity="0.5"
               :width="70"
               :height="70">
        <rainbow-loader></rainbow-loader>
      </loading>
    </div>
  </div>
</template>

<script>
  import 'vue-croppa/dist/vue-croppa.css';
  import { required, maxLength } from 'vuelidate/lib/validators';
  import BreakpointMixin from '@/mixins/BreakpointMixin';
  import ProfanityMixin from '../../mixins/ProfanityMixin';

  const Loading = () => import('vue-loading-overlay');
  const RainbowLoader = () => import('@/components/shared/RainbowLoader');

  const mustBeImg = function (value) {
    const allowedTypes = [ 'image/jpeg', 'image/png', 'image/jpg' ];
    let isValid = false;

    if (value !== null)
      isValid = (!allowedTypes.includes(value.type)) ? false : true;
    else
      isValid = true;

    return isValid;
  };

  export default {
    name  : 'EditClassroom',
    props : {
      classroom : Object,
    },
    data() {
      return {
        isLoading         : false,
        hasUpdatedImage   : false,
        file              : {},
        selectedThumbnail : null,
        uploading         : false,
        placeholder       : 'Choose an image',
        currentImage      : this.classroom.thumbnail,
        currentName       : this.classroom.classroom_name,
        form              : {
          classroomName : null,
          selected      : null,
          thumbnail     : null,
          showQRCode    : Boolean(this.classroom.show_qr_code),
        },
        avatars : [ {
          name : 'Pepper',
          img  : process.env.VUE_APP_ADMIN_BUCKET_BASE_URL +
            'classroom_thumbnails/pepper/pepper.png',
        }, {
          name : 'Broccoli',
          img  : process.env.VUE_APP_ADMIN_BUCKET_BASE_URL +
            'classroom_thumbnails/brocolli/brocolli.png',
        }, {
          name : 'Garlic',
          img  : process.env.VUE_APP_ADMIN_BUCKET_BASE_URL +
            'classroom_thumbnails/garlic/garlic.png',
        }, {
          name : 'Spring Onion',
          img  : process.env.VUE_APP_ADMIN_BUCKET_BASE_URL +
            'classroom_thumbnails/spring_onion/spring_onion.png',
        }, {
          name : 'Eggplant',
          img  : process.env.VUE_APP_ADMIN_BUCKET_BASE_URL +
            'classroom_thumbnails/eggplant_1/eggplant_1.png',
        } ],
        uniqueClassroom   : true,
        editedForm        : false,
        safeClassroomName : true,
      }
    },
    mixins : [
      BreakpointMixin,
      ProfanityMixin,
    ],
    computed : {
      classroomName() {
        return this.form.classroomName;
      },
      showQRCode() {
        return this.form.showQRCode;
      },
    },
    components : {
      Loading,
      RainbowLoader,
    },
    watch : {
      classroomName(str) {
        this.editedForm = false;
        if (this.form.classroomName !== this.classroom.classroom_name) {
          this.editedForm = true;
          this.checkClassroomName();
          if (str.length)
            this.checkProfanity('safeClassroomName', str);
          else
            this.safeClassroomName = true;
          
        }
      },
      showQRCode(value) {
        value = (value === true) ? 1 : 0;
        this.editedForm = false;
        if (value !== this.classroom.show_qr_code)
          this.editedForm = true;
      },
      hasUpdatedImage(value) {
        this.editedForm = false;
        if (value)
          this.editedForm = true;
      },
    },
    validations : {
      form : {
        classroomName : {
          required,
          maxLength : maxLength(30),
          uniqueClassroom() {
            return this.uniqueClassroom;
          },
          safeClassroomName() {
            return this.safeClassroomName;
          },
        },
      },
      file : { mustBeImg },
    },
    methods : {

      /**
       * Check if classroom name is existing
       */
      // eslint-disable-next-line no-undef
      checkClassroomName : _.debounce(function() {
        this.$http.get('api/classroom/check', {
          params : {
            schoolId    : this.$store.getters['user/user'].school_id,
            classroomId : this.classroom.classroom_id,
            name        : this.form.classroomName,
          },
        }).then(response => {
          this.uniqueClassroom = (response.data) ? false : true;
        });
      }, 500),

      /**
       * Edit Classroom
       */
      editClassroom() {
        this.isLoading = true;
        const that = this;
        if (this.hasUpdatedImage && this.selectedThumbnail === null &&
          this.form.classroomName === this.currentName)
          this.uploadFile(this.classroom.classroom_id);
        else {
          this.$http.put('api/classroom', {
            classroomId  : this.classroom.classroom_id,
            updateFields : {
              // eslint-disable-next-line camelcase
              classroom_name : this.form.classroomName,
              thumbnail      : this.form.thumbnail,
              // eslint-disable-next-line camelcase
              show_qr_code   : (this.form.showQRCode === true) ? 1 : 0,
            },
          }).then(() => {
            if (!this.hasUpdatedImage || this.selectedThumbnail !== null) {
              setTimeout(function() {
                that.$emit('close');
                that.isLoading = false;
              }, 1500);
              this.$notify({
                group : "notif",
                type  : "success",
                title : "Success",
                text  : "Classroom has been successfully updated.",
              });
            } else
              this.uploadFile(this.classroom.classroom_id);
            
          }).catch(() => {
            this.isLoading = false;
            this.$notify({
              group : "notif",
              type  : "error",
              title : "Oops Something Went Wrong!",
              text  : "Please try again later.",
            });
          });
        }

      },

      /**
       * Select Classroom Thumbnail
       */
      selectThumbnail(index){
        this.form.selected = index;
        this.selectedThumbnail = this.avatars[index].img;
        this.form.thumbnail = this.avatars[index].img;
        this.hasUpdatedImage = true;
      },

      /**
       * Check if File type is valid
       */
      onFileTypeMismatch () {
        this.placeholder = 'Please select a jpeg or png file.';
      },

      /**
       * Disable button on image croppa update
       *
       * @param status
       */
      imageUpdate(status) {
        if (status === 'removed') {
          this.selectedThumbnail = null;
          this.hasUpdatedImage = false;
        } else
          this.hasUpdatedImage = true;
        

      },

      /**
       * Reset Selected file
       */
      resetSelected() {
        this.selectedThumbnail = null;
        this.form.thumbnail = this.currentImage;
        this.hasUpdatedImage = false;
      },

      /**
       * Upload File
       */
      uploadFile(classroomId) {
        this.uploading = true;
        this.file.generateBlob(async (blob) => {
          const formData = new FormData();
          formData.append('file', blob, 'image.jpg');
          formData.append('imageType', 'classroom');
          formData.append('id', classroomId);

          try {
            this.uploading = true;
            const res = await this.$http.post('/api/upload/image', formData, {
              onUploadProgress : e =>
                this.progress =  Math.round(e.loaded * 100 / e.total),
            });

            if (res) {
              const that = this;
              this.uploading = false;
              setTimeout(function () {
                that.$emit('close');
                that.isLoading = false;
              }, 2000);
              this.$notify({
                group : 'notif',
                type  : 'success',
                title : 'Success',
                text  : 'Classroom has been successfully updated..',
              });
            }
          } catch (error) {
            this.isLoading = false;
            this.$notify({
              group : 'notif',
              type  : 'failed',
              title : 'Failed',
              text  : 'Oops! Something went wrong!',
            });
          }
        });
      },
    },
    async mounted(){
      this.form.classroomName = this.classroom.classroom_name;
      const index = await this.avatars.findIndex(x =>
        x.img == this.classroom.thumbnail);
      this.form.selected = index;
      this.form.thumbnail = this.classroom.thumbnail
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/components/modals/edit-classroom";
</style>
