var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dialog"},[_c('div',{staticClass:"dialog-header"},[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col"},[_c('img',{staticClass:"close-button",attrs:{"src":require("../../assets/images/close.png"),"alt":"close-button"},on:{"click":function($event){return _vm.$emit('close')}}})])])]),_c('div',{staticClass:"dialog-content",staticStyle:{"overflow-y":"auto"},style:({height: _vm.contentHeightOverride})},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.editClassroom.apply(null, arguments)}}},[_c('b-input-group',{attrs:{"prepend":"Classroom Name"}},[_c('b-form-input',{attrs:{"id":"classroom-name","type":"text","aria-describedby":"formClasroomName","placeholder":"Classroom Name","state":!_vm.$v.form.classroomName.$error},on:{"input":function($event){return _vm.$v.form.classroomName.$touch()}},model:{value:(_vm.form.classroomName),callback:function ($$v) {_vm.$set(_vm.form, "classroomName", $$v)},expression:"form.classroomName"}}),_c('b-form-invalid-feedback',{attrs:{"id":"formClasroomName"}},[(!_vm.$v.form.classroomName.required)?_c('span',[_vm._v(" Classroom name is required ")]):_vm._e(),(_vm.$v.form.classroomName.required &&
            !_vm.$v.form.classroomName.uniqueClassroom)?_c('span',[_vm._v(" Classroom name must be unique. ")]):_vm._e(),(!_vm.$v.form.classroomName.safeClassroomName)?_c('span',[_vm._v(" Classroom name not allowed. ")]):_vm._e(),(!_vm.$v.form.classroomName.maxLength)?_c('span',[_vm._v(" Classroom Name should have a maximum of "+_vm._s(_vm.$v.form.classroomName.$params.maxLength.max)+" characters ")]):_vm._e()])],1),_c('b-input-group',{attrs:{"prepend":"Show QR Code"}},[_c('toggle-button',{staticClass:"large-font ml-2 mb-0",attrs:{"id":"showQRCode","width":100,"height":50,"labels":{
                         checked   : 'On',
                         unchecked : 'Off',
                       }},model:{value:(_vm.form.showQRCode),callback:function ($$v) {_vm.$set(_vm.form, "showQRCode", $$v)},expression:"form.showQRCode"}})],1),_c('div',{staticClass:"create-classroom-row"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-sm-4 d-flex align-items-center my-auto text-center"},[(_vm.selectedThumbnail != null)?_c('div',{staticClass:"photo-placeholder"},[_c('i',{staticClass:"fas fa-times-circle remove-icon",on:{"click":_vm.resetSelected}}),_c('img',{staticClass:"current-img img-fluid",attrs:{"src":_vm.selectedThumbnail,"alt":"selected-avatar","width":"166","height":"166"}})]):_c('div',{staticClass:"photo-placeholder"},[_c('croppa',{attrs:{"accept":".jpeg,.png,.jpg","width":166,"height":166,"placeholder":_vm.placeholder,"placeholder-color":"#068bcd","placeholder-font-size":12,"prevent-white-space":true,"canvas-color":"#fff","show-remove-button":true,"remove-button-color":"red","remove-button-size":25,"show-loading":true,"loading-size":25,"initial-image":_vm.currentImage},on:{"file-type-mismatch":_vm.onFileTypeMismatch,"new-image":function($event){return _vm.imageUpdate('new')},"image-remove":function($event){return _vm.imageUpdate('removed')}},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}}),_c('div',{staticClass:"buttons pb-2"},[_c('i',{staticClass:"fas fa-search-plus icon",on:{"click":function($event){return _vm.file.zoomIn()}}}),_c('i',{staticClass:"fas fa-search-minus icon",on:{"click":function($event){return _vm.file.zoomOut()}}})])],1)]),_c('div',{staticClass:"col-12 col-sm-8 text-center"},[_c('div',{staticClass:"avatars"},[_vm._m(1),_c('div',{staticClass:"avatars-row"},_vm._l((_vm.avatars),function(avatar,index){return _c('span',{key:index,staticClass:"position-relative ml-1 mr-1",on:{"click":function($event){return _vm.selectThumbnail(index)}}},[_c('img',{staticClass:"avatar border",class:{
                         'border-dark': (index == _vm.form.selected) ?
                           true : false
                       },attrs:{"src":avatar.img,"alt":avatar.name}})])}),0)])])])]),_c('div',{staticClass:"text-right"},[_c('b-button',{staticClass:"btn btn-success btn-h3-small",attrs:{"type":"button","disabled":_vm.isLoading || _vm.$v.form.$invalid || !_vm.editedForm},on:{"click":_vm.editClassroom}},[_vm._v(" Done ")])],1)],1),_c('loading',{attrs:{"active":_vm.isLoading,"is-full-page":true,"background-color":"#000","color":"#068bcd","opacity":0.5,"width":70,"height":70},on:{"update:active":function($event){_vm.isLoading=$event}}},[_c('rainbow-loader')],1)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col"},[_c('p',{staticClass:"dialog-title text-uppercase mb-0"},[_vm._v("Edit Classroom")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"small text-white mt-3"},[_vm._v(" Pick a thumbnail "),_c('br'),_vm._v(" (H3 Characters) ")])
}]

export { render, staticRenderFns }